import { Component, ElementRef, HostBinding, ViewChild } from '@angular/core';
import { SettingsService } from './shared/settings/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @ViewChild('mainContainer')
  mainContainer: ElementRef | undefined;

  constructor(private _settings: SettingsService) {
    this._settings.animationFactor.value$.subscribe((v) => {
      this.animationFactor = v;
    });
  }

  @HostBinding('style.--animation-factor')
  animationFactor = this._settings.animationFactor.value;

  readonly debugPaths = [
    'trial',
    'battle',
    'draw-map',
    'draw-character',
    'icons',
    'dice',
  ];

  goFullscreen() {
    this.mainContainer?.nativeElement.requestFullscreen();
  }
}
