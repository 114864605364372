<header>
  <h1>Dicey Expeditions</h1>
  <nav>
    <ul>
      <li><a routerLink="/rules">Rules</a></li>
      <li><a routerLink="/rules/beastiary">Beastiary</a></li>
      <li><a routerLink="/tutorial/settings">Settings</a></li>
      <li><a routerLink="/tutorial">Tutorial</a></li>
    </ul>
  </nav>
  <button (click)="goFullscreen()">Fullscreen</button>
</header>

<main #mainContainer>
  <router-outlet></router-outlet>
</main>

<footer>
  <nav>
    <ul class="legal">
      <li><a routerLink="/about/license">License Attribution</a></li>
      <li><a routerLink="/about/imprint">Imprint</a></li>
    </ul>

    <ul class="debug">
      <li>Debug:</li>
      <li *ngFor="let target of debugPaths">
        <a routerLink="/debug/{{ target }}">{{ target }}</a>
      </li>
    </ul>
  </nav>
</footer>
