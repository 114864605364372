import { Injectable } from '@angular/core';
import { delay } from 'rxjs';
import { SettingsValue } from './settings-value';

/**
 * Keeps track of all sorts of settings that a user may want to have
 * persisted over multiple sessions.
 */
@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor() {}

  readonly animationFactor = new SettingsValue<number>('animationFactor', 1);

  animationDelay<T>(delayMs = 700) {
    return delay<T>(delayMs * this.animationFactor.value);
  }
}
