import { BrowserModule } from '@angular/platform-browser';
import { Inject, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from './shared/shared.module';
import { enableMapSet } from 'immer';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, SharedModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Inject(LOCALE_ID)
    private readonly locale: string
  ) {
    // Exactly once: Activate support for Immer features that we require
    enableMapSet();
    console.log('Immer plugins enabled');

    console.log('Starting DiceyExpeditions, locale is ', this.locale);
  }
}
