import { BehaviorSubject, Observable } from 'rxjs';

export class SettingsValue<T extends string | number> {
  private readonly _value$: BehaviorSubject<T>;

  readonly value$: Observable<T>;

  constructor(readonly name: string, defaultValue: T) {
    const initialValue = this.initialValue(defaultValue);

    this._value$ = new BehaviorSubject(initialValue);
    this.value$ = this._value$.asObservable();
  }

  get value() {
    return this._value$.value;
  }

  set value(v: T) {
    this._value$.next(v);
    if (localStorage) {
      localStorage.setItem(this.name, v.toString());
    }
  }

  private initialValue(defaultValue: T): T {
    const storedString = localStorage?.getItem(this.name);
    if (storedString) {
      switch (typeof defaultValue) {
        case 'number':
          return +storedString as T;
        case 'string':
          return storedString as T;
      }
    }

    return defaultValue;
  }
}
