import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TokenIconComponent } from './dice/token-icon.component';
import { DiceUnraveledComponent } from './dice/dice-unraveled.component';
import { CharacterSummaryComponent } from './character/character-summary.component';
import { CharacterSpriteComponent } from './character/character-sprite.component';
import { DiceSideComponent } from './dice/dice-side.component';
import { RunBattleComponent } from './battle/run-battle.component';
import { BattleDiceComponent } from './battle/battle-dice.component';
import { BattleCharacterSidebarComponent } from './battle/battle-character-sidebar.component';
import { BattleCharacterStageComponent } from './battle/battle-character-stage.component';
import { SelectionFrameComponent } from './player/selection-frame.component';
import { SelectionFrameOptionComponent } from './player/selection-frame-option.component';
import { SelectPlayersComponent } from './player/select-players.component';
import { SettingsComponent } from './settings/settings.component';
import { AnimationSettingsComponent } from './settings/animation-settings.component';
import { PoseTrialComponent } from './trial/pose-trial.component';
import { TrialOutcomeComponent } from './trial/trial-outcome.component';
import { UserLanguagePipe } from './multilang/user-language.pipe';
import { DrawMapComponent } from './draw-tiled/draw-map.component';
import { SpriteIconComponent } from './sprite-sheet/sprite-icon.component';
import { EquipmentComponent } from './equipment/equipment.component';
import { ExpeditionCharacterDicePipe } from './expedition/all-dice.pipe';
import { AbilityComponent } from './ability/ability.component';
import { DiceChancesComponent } from './dice/dice-chances.component';
import { HorizontalBarsComponent } from './dice/horizontal-bars.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [
    TokenIconComponent,
    DiceUnraveledComponent,
    CharacterSummaryComponent,
    CharacterSpriteComponent,
    DiceSideComponent,
    DiceChancesComponent,
    RunBattleComponent,
    BattleDiceComponent,
    BattleCharacterSidebarComponent,
    BattleCharacterStageComponent,
    SelectionFrameComponent,
    SelectionFrameOptionComponent,
    SelectPlayersComponent,
    SettingsComponent,
    AnimationSettingsComponent,
    PoseTrialComponent,
    TrialOutcomeComponent,
    UserLanguagePipe,
    DrawMapComponent,
    SpriteIconComponent,
    EquipmentComponent,
    ExpeditionCharacterDicePipe,
    AbilityComponent,
    HorizontalBarsComponent,
  ],
  exports: [
    TokenIconComponent,
    DiceUnraveledComponent,
    DiceChancesComponent,
    CharacterSummaryComponent,
    CharacterSpriteComponent,
    RunBattleComponent,
    SelectionFrameComponent,
    PoseTrialComponent,
    DrawMapComponent,
    SpriteIconComponent,
    EquipmentComponent,
    AbilityComponent,
  ],
})
export class SharedModule {}
